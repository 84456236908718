<template>
  <div>
    <vx-card
      ><h3 class="mb-5 text-info">{{ $t("Editar regla") }}</h3>
      <div class="grid grid-cols-2 gap-5">
        <div class="col-span-full">
          <label for="rule-name">{{ $t("Nombre de la regla") }}:</label>
          <vs-input v-model="form.name" id="promotion-name" class="w-full" />
          <p
            v-if="errors.name"
            class="text-sm font-light span-text-validation-danger"
          >
            {{ errors.name }}
          </p>
        </div>
      </div>
      <div class="mt-4 flex justify-end gap-5">
        <vs-button
          :color="'#FFF'"
          :text-color="'#666'"
          class="rounded-full"
          @click.native="$router.go(-1)"
        >
          {{ $t("Cancelar") }}
        </vs-button>
        <vs-button
          class="rounded-full"
          @click.native="editRule"
          :disabled="loading"
        >
          {{ $t("Agregar") }}
        </vs-button>
      </div></vx-card
    >
  </div>
</template>
<script>
import VSelect from "vue-select";
import http from "@/http/promotions-http-client.js";
export default {
  name: "EditRulePage",
  components: {
    VSelect,
  },
  data() {
    return {
      form: {
        name: "",
      },
      errors: {
        name: "",
      },
      loading: false,
    };
  },
  async created() {
    await Promise.allSettled([this.getRule()]);

    this.$store.dispatch("updatePageTitle", {
      title: this.$t("Reglas"),
      subtitle: this.$t("Edita una regla"),
    });
  },
  methods: {
    async getRule() {
      const id = this.$route.params.id || "";
      this.loading = true;
      const response = await http.get(`api/RulesPromotionsSh/${id}`);
      this.loading = false;
      if (!response || response.status !== 200) return;
      const { name } = response.data;
      this.form = { name };
    },
    async editRule() {
      if (this.isInvalidForm()) return;
      const id = this.$route.params.id || "";
      this.loading = true;
      const response = await http.put(`api/RulesPromotions/${id}`, {
        ...this.form,
      });
      this.loading = false;
      if (!response || response.status !== 201) return;
      this.$router.go(-1);
    },
    isInvalidForm() {
      this.cleanErrors();
      let invalid = false;
      for (const input in this.form) {
        if (this.form[input] === "") {
          this.errors[input] = this.$t("Campo requerido");
          invalid = true;
        }
      }
      return invalid;
    },
    cleanErrors() {
      for (const error in this.errors) {
        this.errors[error] = "";
      }
    },
  },
};
</script>
